import React, { useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moobitekLogo from "../../assets/MoobitekLogos/MoobitekFinalLogowhite.png";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faXTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import GamingLabsCertifiedReverse from "../../assets/MoobitekLogos/GamingLabsCertifiedReverse.png"
import pdfFile from "../../assets/pdfFiles/InformationSecurityPolicy.pdf"


function Footer() {
  const currentYear = new Date().getFullYear();
  const [dropDown, setDropDown] = useState("hidden");

  const handleDownload = (pdfPath, filename) => {
    const link = document.createElement('a');
    link.href = pdfPath;  
    link.download = filename ; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); 
  };
  
  const openDropDown = () => {
    setDropDown((currentValue) =>
      currentValue === "visible" ? "hidden" : "visible"
    );
  };
  return (
    <footer className={classes.footer}>
      <div>
        <ul className={classes.ul_list} style={{ padding: "0" }}>
          <li className={classes.navigateTabs}>
            <Link to="/AboutUs" className={classes.list_wordFirst}>
              Why Moobitek
            </Link>
          </li>
          <li className={classes.navigateTabs}>
            <Link to="/Publications" className={classes.list_wordFirst}>
              Publications
            </Link>
          </li>
          <li className={classes.navigateTabs}>
            <Link to="/ContactUs" className={classes.list_wordFirst}>
              Contact Us
            </Link>
          </li>
          {/* <li className={classes.navigateTabs}>
            <Link to="/ContactUs" className={classes.list_wordFirst}>
              Policies
            </Link>
          </li> */}
          <li className={classes.navigateTabsSecond} onClick={openDropDown}>
            <Link to="" className={classes.list_wordFirst + ' ' + classes.verticalsMeth}>
              Verticals
            </Link>
            <FontAwesomeIcon
              className={`${classes.iconClose} ${dropDown === "visible" ? classes.up : ""
                }`}
              icon={faChevronDown}
            />
          </li>
        </ul>
        <div
          className={`${classes.dropdownContent} ${dropDown === "visible" ? classes.visible : ""
            }`}
        >
          <ul className={classes.ul_listSecond} style={{ padding: "0" }}>
            <li className={classes.navigateTabsSecond}>
              <Link to="/MoobiPlus" className={classes.list_wordSecondLink}>
                MoobiPlus
              </Link>
            </li>
            <li className={classes.navigateTabsSecond}>
              <Link to="/MoobiVault" className={classes.list_wordSecondLink}>
                MoobiVault
              </Link>
            </li>
            <li className={classes.navigateTabsSecond}>
              <Link to="/MoobiPay" className={classes.list_wordSecondLink}>
                MoobiPay
              </Link>
            </li>
            <li className={classes.navigateTabsSecond}>
              <Link to="/MoobiDev" className={classes.list_wordSecondLink}>
                MoobiDev
              </Link>
            </li>
          </ul>
        </div>
        <div className={classes.desktop}>
          <ul className={classes.ul_listSecond1} style={{ padding: "0" }}>
            <li className={classes.navigateTabsSecond1}>
              <Link to="/MoobiPlus" className={classes.list_wordSecondLink1}>
                MoobiPlus
              </Link>
            </li>
            <li className={classes.navigateTabsSecond1}>
              <Link to="/MoobiVault" className={classes.list_wordSecondLink1}>
                MoobiVault
              </Link>
            </li>
            <li className={classes.navigateTabsSecond1}>
              <Link to="/MoobiPay" className={classes.list_wordSecondLink1}>
                MoobiPay
              </Link>
            </li>
            <li className={classes.navigateTabsSecond1}>
              <Link to="/MoobiDev" className={classes.list_wordSecondLink1}>
                MoobiDev
              </Link>
            </li>
          </ul>
        </div>

        <div className={classes.lineSeperate} />
        <div className={classes.logoMediaContainer}>
          <Link to="/">
            <img
              src={moobitekLogo}
              alt="moobitek Logo "
              className={classes.logo}
            />
          </Link>
          <div className={classes.rightPrivacyStyle}>
            <a href="https://access.gaminglabs.com/Certificate/Index?i=568" target="_blank" rel="noopener noreferrer">
              <img src={GamingLabsCertifiedReverse} className={classes.gamingStyleLogo} />
            </a>
            <span className={classes.rightsSentence}>
              © {currentYear} Moobitek, LLC.
            </span>
            <span
              className={classes.policySentence1}
              onClick={() => handleDownload(pdfFile, 'Information Security Policy.pdf')}
            >
              Policies
            </span>
          </div>
          <div className={classes.rightPrivacyStyle} >
            <span
              className={classes.policySentence}
              onClick={() => handleDownload(pdfFile, 'Information Security Policy.pdf')}
            >
              Policies
            </span>

          </div>
          <div className={classes.footerStyle}>
            <div>
              <a href="https://www.facebook.com/Moobitek" target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  color="#ffffff"
                  className={classes.mediaIcon}
                />
              </a>
              <a
                href="https://twitter.com/i/flow/login?redirect_after_login=%2Fmoobitek"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faXTwitter}
                  color="#ffffff"
                  className={classes.mediaIcon}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/moobitek"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  color="#ffffff"
                  className={classes.mediaIcon}
                />
              </a>
              <a href="https://www.instagram.com/moobitek.leb" target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faInstagram}
                  color="#ffffff"
                  className={classes.mediaIcon}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;